import axiosIns from "@/libs/axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchSettings(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    //.get("user", { params: queryParams })
                    .get("settings", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        fetchUser(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get(`user/${id}`)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        staffUpdate(ctx, staffData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("updateStaff", staffData)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        deleteEvents(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("deleteEvents", { data: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        getTimeline(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    //.get("user", { params: queryParams })
                    .get("timeline")
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        getSelectWeek(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns

                    .get("getSelectWeek", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        sendSelectWeek(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns

                    .get("sendSelectWeek", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        staffPlannerDropDown(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get("staffPlannerDropDown", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        changeNote(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get("changeNote", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        

        changeNoteEditList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("changeNoteEditList",queryParams )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        saveEventHotel(ctx, hotelData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("saveEventHotel", hotelData)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        saveGroupEventHotel(ctx, hotelData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("saveGroupEventHotel", hotelData)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        saveHotelEditList(ctx, hotelData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("saveHotelEditList", hotelData)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        removeEventHotel(ctx, hotelData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("removeEventHotel", hotelData)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        removeGroupEventHotel(ctx, hotelData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("removeGroupEventHotel", hotelData)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        removeHotelEditList(ctx, hotelData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("removeHotelEditList", hotelData)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        editGroupEventHotel(ctx, hotelData) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("editGroupEventHotel", hotelData)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        editHotelEditList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("editHotelEditList", queryParams )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        checkForemenList(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("checkForemenList", data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },


        

        addStaff(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get("addStaff", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        addStaffList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get("addStaffList", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        addStaffGroupList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get("addStaffGroupList", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
        
        addStaffGroupListSingle(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get("addStaffGroupListSingle", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        editStaffGroupListSingle(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get("editStaffGroupListSingle", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        


        

        

        addStaffEditList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("addStaffEditList", queryParams )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        editStaffEditList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("editStaffEditList", queryParams )
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },


 

        deleteStaff(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .get("deleteStaff", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },

        showAllEvents(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns

                    .get("showAllEvents", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        weekDataOne(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns

                    .get("weekDataOne", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        weekDataTwo(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns

                    .get("weekDataTwo", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        weekDataThree(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns

                    .get("weekDataThree", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        employeeList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns

                    .get("employeeList", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        showWeeks(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns

                    .get("showWeeks", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        howManyWeeks(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axiosIns

                    .get("howManyWeeks", { params: queryParams })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        printer(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("printer", data, { responseType: "blob" })
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },

        printer2(ctx, data) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post("printer", data)
                    .then((response) => {
                        resolve(response);
                    })

                    .catch((error) => reject(error));
            });
        },
    },
};
