var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',{staticClass:"leftTitle"},[_vm._v("Staf")]),_vm._l((_vm.saveDatas.length),function(y,yIndex){return _c('td',{key:yIndex,staticClass:"plannerTdStaff",style:({ 'background-color': _vm.indexColors[yIndex] })},[_vm._l((_vm.saveDatas[yIndex].jobs[_vm.xIndex].team_list),function(staff,index){return _c('li',{key:index,staticClass:"mb-25 w-100 text-left",class:{
          errorStaff:
            _vm.staffAvailablity(staff.id, _vm.saveDatas[yIndex].jobs[_vm.xIndex].date) ==
            1,
          warningStaff:
            _vm.staffAvailablity(staff.id, _vm.saveDatas[yIndex].jobs[_vm.xIndex].date) ==
            2,

          wwsuccess: !(
            _vm.selectedLiIndex.staffId === staff.id &&
            _vm.selectedLiIndex.eventId === _vm.saveDatas[yIndex].jobs[_vm.xIndex].id
          ),
        },staticStyle:{"cursor":"pointer","transition":"all 0.3s ease"},style:({
          'background-color': _vm.saveDatas[yIndex].jobs[_vm.xIndex].team_back_colour,
          color: _vm.saveDatas[yIndex].jobs[_vm.xIndex].team_text_colour,
        }),on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleClick(
            $event,
            staff.id,
            _vm.saveDatas[yIndex].jobs[_vm.xIndex],
            staff.status
          )}}},[(
            _vm.selectedLiIndex.staffId === staff.id &&
              _vm.selectedLiIndex.eventId === _vm.saveDatas[yIndex].jobs[_vm.xIndex].id
          )?_c('div',[_c('v-select',{staticClass:"select-size-sm",staticStyle:{"padding":"0px","margin":"0px","background-color":"white","z-index":"9","line-height":"0.8","font-size":"0.875em","transition":"all 0.3s ease","color":"rgba(60, 60, 60)"},attrs:{"input-id":"staffInput","reduce":function (val) { return val; },"label":"full_name","options":_vm.filteredStaffList,"clearable":false},on:{"input":function($event){return _vm.changeStaff($event, _vm.saveDatas[yIndex].jobs[_vm.xIndex])}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
          var full_name = ref.full_name;
          var availabilities = ref.availabilities;
          var role = ref.role;
return [(role == 'Foreman')?_c('span',{class:_vm.selectDropDownCheck(
                    _vm.saveDatas[yIndex].jobs[_vm.xIndex].date,
                    availabilities
                  )
                    ? 'bg-danger p-25 text-white'
                    : ''},[_vm._v("🤵🏻 "+_vm._s(full_name))]):_c('span',{class:_vm.selectDropDownCheck(
                    _vm.saveDatas[yIndex].jobs[_vm.xIndex].date,
                    availabilities
                  )
                    ? 'bg-danger p-25 text-white'
                    : ''},[_vm._v("🧍🏻 "+_vm._s(full_name))])]}}],null,true),model:{value:(_vm.selectedStaffId),callback:function ($$v) {_vm.selectedStaffId=$$v},expression:"selectedStaffId"}})],1):_c('div',{on:{"click":function($event){return _vm.eventEmployeeListChange(_vm.saveDatas[yIndex].jobs[_vm.xIndex])},"dblclick":function($event){return _vm.staffClick(_vm.saveDatas[yIndex].jobs[_vm.xIndex].id, staff.id)}}},[(staff.role == 'Foreman')?_c('span',[_vm._v("🤵🏻")]):_c('span',[_vm._v("🧍🏻 ")]),_c('span',[_vm._v(_vm._s(staff.name)+" "+_vm._s(staff.surname)+" ")])])])}),_vm._l((_vm.getRequired(_vm.saveDatas[yIndex].jobs[_vm.xIndex])),function(i,keyIndex){return (
    _vm.saveDatas[yIndex].jobs[_vm.xIndex].employess_required -
      _vm.saveDatas[yIndex].jobs[_vm.xIndex].actual >
      0 && _vm.$Can('staff_modal_add_btn')
  )?_c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.formShow),expression:"!formShow"}],staticClass:"mb-25 w-100 text-left transitionEffect"},[(_vm.showSelect != _vm.saveDatas[yIndex].jobs[_vm.xIndex])?_c('div',{staticClass:"select-size-sm",staticStyle:{"padding":"0px","margin":"0px","background-color":"white","line-height":"0.6","font-size":"0.875em","transition":"all 0.3s ease-out","color":"rgba(60, 60, 60)","height":"2.8em!important","width":"auto","border":"1px solid lightgray","border-radius":"5px","justify-content":"end","display":"flex"},on:{"click":function($event){_vm.showSelect = _vm.saveDatas[yIndex].jobs[_vm.xIndex]}}}):_vm._e(),(_vm.showSelect == _vm.saveDatas[yIndex].jobs[_vm.xIndex])?_c('v-select',{staticClass:"select-size-sm",staticStyle:{"padding":"0px","margin":"0px","background-color":"white","line-height":"0.8","font-size":"0.875em","transition":"all 0.3s ease-out","color":"rgba(60, 60, 60)"},attrs:{"input-id":"staffInput","reduce":function (val) { return val; },"label":"full_name","options":_vm.requiredStaffList,"clearable":false},on:{"open":function($event){return _vm.oldSelectEventClear(
        _vm.saveDatas[yIndex].jobs[_vm.xIndex].id,
        _vm.saveDatas[yIndex].jobs[_vm.xIndex].date
      )},"input":function($event){return _vm.addStaff(2, _vm.saveDatas[yIndex].jobs[_vm.xIndex])}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
      var full_name = ref.full_name;
      var availabilities = ref.availabilities;
      var role = ref.role;
return [(role == 'Foreman')?_c('span',{class:_vm.selectDropDownCheck(
            _vm.saveDatas[yIndex].jobs[_vm.xIndex].date,
            availabilities
          )
            ? 'bg-danger p-25 text-white'
            : ''},[_vm._v(" 🤵🏻 "+_vm._s(full_name)+" ")]):_c('span',{class:_vm.selectDropDownCheck(
            _vm.saveDatas[yIndex].jobs[_vm.xIndex].date,
            availabilities
          )
            ? 'bg-danger p-25 text-white'
            : ''},[_vm._v(" 🧍🏻 "+_vm._s(full_name)+" ")])]}}],null,true),model:{value:(_vm.selectedNewStaffId),callback:function ($$v) {_vm.selectedNewStaffId=$$v},expression:"selectedNewStaffId"}}):_vm._e()],1):_vm._e()})],2)}),(_vm.$Can('staff_modal_add_btn'))?_c('vue-context',{ref:"staffMenuData"},[_c('li',_vm._l((_vm.staffMenuData),function(data){return _c('b-link',{key:data.text,staticClass:"d-flex align-items-center",on:{"click":function($event){return _vm.optionClicked(data.text, data)}}},[_c('feather-icon',{attrs:{"icon":data.icon,"size":"12"}}),_c('span',{staticClass:"ml-75"},[_vm._v(_vm._s(data.text))])],1)}),1)]):_vm._e(),_c('b-modal',{ref:_vm.modalGroupDates,attrs:{"id":_vm.modalGroupDates,"cancel-variant":"outline-secondary","ok-title":"Save","cancel-title":"Cancel","no-close-on-backdrop":true,"centered":""},on:{"cancel":_vm.groupDatesHandleCancel},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
            var close = ref.close;
return [_c('span',[_vm._v("Select Staff Group Dates")])]}}])},[_c('b-form',{ref:"groupDatesRules"},[_c('b-form-group',{attrs:{"state":_vm.groupDatesState,"invalid-feedback":"Date field is required","label":"Date*","label-for":"date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date","state":_vm.groupDatesState,"input-id":"date","required":"","config":_vm.configPickr()},model:{value:(_vm.groupDates),callback:function ($$v) {_vm.groupDates=$$v},expression:"groupDates"}})],1)],1)],1),(_vm.$Can('staff_modal_add_btn'))?_c('b-modal',{ref:"modalStaffSet",attrs:{"id":"modal-staff-set","no-close-on-backdrop":"","size":"lg","centered":"","ok-title":"Save","cancel-variant":"outline-secondary"},on:{"ok":_vm.staffHandleOk,"cancel":_vm.staffHandeCancel},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
            var close = ref.close;
return [_c('span',[_vm._v(_vm._s("Event Date : " + _vm.dateFormat(_vm.modalSelectDate)))])]}}],null,false,2848376902)},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('b-form-group',{attrs:{"label":"Staff Select","label-for":"role"}},[_c('v-select',{attrs:{"options":_vm.filteredNewStaffList,"reduce":function (val) { return val; },"label":"full_name","clearable":false,"input-id":"role"},on:{"input":_vm.staffExistsCheck},scopedSlots:_vm._u([{key:"option",fn:function(ref){
            var full_name = ref.full_name;
            var availabilities = ref.availabilities;
            var role = ref.role;
return [(role == 'Foreman')?_c('span',{class:_vm.selectDropDownCheck(_vm.modalSelectDate, availabilities)
                        ? 'bg-danger p-25 text-white'
                        : ''},[_vm._v("🤵🏻 "+_vm._s(full_name))]):_c('span',{class:_vm.selectDropDownCheck(_vm.modalSelectDate, availabilities)
                        ? 'bg-danger p-25 text-white'
                        : ''},[_vm._v("🧍🏻 "+_vm._s(full_name))])]}}],null,false,3258616000),model:{value:(_vm.staffSelect),callback:function ($$v) {_vm.staffSelect=$$v},expression:"staffSelect"}})],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.$Can('staff_modal_add_btn')),expression:"$Can('staff_modal_add_btn')"}],attrs:{"cols":"2"}},[_c('b-form-group',{staticClass:"mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"disabled":_vm.addButtonCheck,"variant":"primary"},on:{"click":_vm.staffAdd}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"striped":"","hover":"","items":_vm.modalTeamList,"filter-debounce":"250","responsive":"","small":true,"head-variant":"dark","fields":_vm.tableColumns,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(role)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(employeeName)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.surname)+" ")]}},{key:"cell(availability)",fn:function(data){return [(
                _vm.staffAvailablityModal(
                  data.item.id,
                  _vm.modalSelectDate,
                  data.item.isNew
                ) == 0
              )?_c('b-badge',{staticClass:"text-capitalize mr-1",attrs:{"pill":"","variant":"success"}},[_vm._v(" Available ")]):(
                _vm.staffAvailablityModal(
                  data.item.id,
                  _vm.modalSelectDate,
                  data.item.isNew
                ) == 1
              )?_c('b-badge',{staticClass:"text-capitalize mr-1",attrs:{"pill":"","variant":"light-danger"}},[_vm._v(" Conflict ")]):(
                _vm.staffAvailablityModal(
                  data.item.id,
                  _vm.modalSelectDate,
                  data.item.isNew
                ) == 2
              )?_c('b-badge',{staticClass:"text-capitalize mr-1",attrs:{"pill":"","variant":"light-warning"}},[_vm._v(" Not Available ")]):_vm._e()]}},{key:"cell(action)",fn:function(data){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon btn-sm",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.staffDelete(data)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)]}}],null,false,2923767089)})],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }