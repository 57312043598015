<template>
  <tr>
    <th class="leftTitle">Hotel</th>
    <td
      class="plannerTd"
      :style="{ 'background-color': indexColors[yIndex] }"
      style="vertical-align: top;"
      v-for="(y, yIndex) in saveDatas.length"
    >
      <li
        v-for="(hotel, index) in saveDatas[yIndex].jobs[xIndex].hotel"
        :key="index"
        class="mb-25"
        style="cursor: pointer; "
      >
        <div>
          <span
            @contextmenu.prevent="
              handleClickHotel(
                $event,
                hotel,
                index,
                saveDatas[yIndex].jobs[xIndex],
                x,
                y
              )
            "
            :class="hotel.name == 'Hotel Not Booked' ? 'fakeBadgeNotBooked' : 'fakeBadge'"
            :id="'popover-badge-' + y + '-' + x + '-' + index"
            ref="button"
          >
            {{ hotel.name }}
          </span>
          <b-popover
            v-if="$Can('event_edit_btn')"
            :id="'popover-' + y + '-' + x + '-' + index"
            ref="popover"
            :target="'popover-badge-' + y + '-' + x + '-' + index"
            triggers="click"
            :show.sync="hotel.popoverShow"
            placement="auto"
            container="my-container"
            @show="onShow(hotel, saveDatas[yIndex].jobs[xIndex])"
          >
            <template v-slot:title>
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ hotel.name }}</span>
                <b-button
                  class="close"
                  variant="transparent"
                  aria-label="Close"
                  @click="onClose(hotel)"
                >
                  <span class="d-inline-block text-white" aria-hidden="true"
                    >&times;</span
                  >
                </b-button>
              </div>
            </template>

            <div>
              <b-form-group
                label="Name"
                label-for="popover-input-1"
                label-cols="3"
                :state="input1state"
                class="mb-1"
                description="Name of the Hotel"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  id="popover-input-1"
                  ref="input1"
                  v-model="input1"
                  size="sm"
                />
              </b-form-group>

              <b-form-group
                label="Price"
                label-for="popover-input-2"
                label-cols="3"
                :state="input2state"
                class="mb-1"
                description="Price of the Hotel"
                invalid-feedback="This field is required"
              >
                <!-- <b-form-input id="popover-input-2" ref="input2" v-model="input2" :state="input2state" size="sm" /> -->

                <cleave
                  id="popover-input-2"
                  ref="input2"
                  required
                  v-model="input2"
                  class="form-control"
                  style="height: 2.142rem;"
                  :raw="true"
                  :options="$func.getInputOptions().price"
                />
              </b-form-group>

              <b-form-group
                label="Staff Count"
                label-for="popover-input-3"
                label-cols="3"
                :state="input2state"
                class="mb-1"
                description="Number of Staff"
                invalid-feedback="This field is required"
              >
                <b-form-input
                  id="popover-input-3"
                  ref="input3"
                  v-model="input3"
                  type="number"
                  size="sm"
                />
              </b-form-group>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="danger"
                class="mr-1"
                @click="onClose(hotel)"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="onOk(saveDatas[yIndex].jobs[xIndex], hotel)"
              >
                Ok
              </b-button>
            </div>
          </b-popover>
        </div>
      </li>
    </td>

    <vue-context v-if="$Can('event_edit_btn')" ref="hotelMenuData">
      <li>
        <b-link
          v-for="data in hotelMenuData"
          :key="data.text"
          class="d-flex align-items-center"
          @click="optionClickedHotel(data.text, data)"
        >
          <feather-icon :icon="data.icon" size="12" />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>

    <b-modal
      :id="modalId"
      cancel-variant="outline-secondary"
      ok-title="Save Hotel"
      @ok="handleHotelOk"
      @show="resetModal"
      cancel-title="Cancel"
      :no-close-on-backdrop="true"
      centered
    >
      <template #modal-header="{ close }">
        <span>Add new Hotel</span>
      </template>
      <div>
        <b-form ref="hotelRules">
          <b-form-group
            label="Name"
            label-for="popover-new-input-1"
            label-cols="3"
            :state="newHotelNameState"
            class="mb-1"
            invalid-feedback="This field is required"
          >
            <b-form-input
              id="popover-new-input-1"
              ref="input1-new"
              required
              v-model="newHotelName"
              size="sm"
            />
          </b-form-group>

          <!-- <b-form-group label="Price" label-for="popover-new-input-2" label-cols="3" :state="newHotelPriceState" class="mb-1" invalid-feedback="This field is required">
                            <b-form-input id="popover-new-input-2" ref="input2-new" required v-model="newHotelPrice" :state="newHotelPriceState" type="number" size="sm" />
                        </b-form-group> -->

          <b-form-group
            label="Price"
            label-for="popover-new-input-2"
            label-cols="3"
            :state="newHotelPriceState"
            class="mb-1"
            invalid-feedback="This field is required"
          >
            <cleave
              id="popover-new-input-2"
              ref="input2-new"
              required
              v-model="newHotelPrice"
              class="form-control"
              style="height: 2.142rem;"
              :raw="true"
              :options="$func.getInputOptions().price"
            />
          </b-form-group>

          <b-form-group
            label="Staff Count"
            label-for="popover-new-input-3"
            label-cols="3"
            :state="newHotelStaffState"
            class="mb-1"
            invalid-feedback="This field is required"
          >
            <b-form-input
              id="popover-new-input-3"
              ref="input3-new"
              required
              v-model="newHotelStaff"
              type="number"
              size="sm"
            />
          </b-form-group>
        </b-form>
      </div>
    </b-modal>

    <b-modal
      :id="modalGroupDates"
      :ref="modalGroupDates"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
      :no-close-on-backdrop="true"
      centered
      @cancel="groupDatesHandleCancel"
    >
      <template #modal-header="{ close }">
        <span>Select Group Dates </span>
      </template>

      <b-form ref="groupDatesRules">
        <b-form-group
          :state="groupDatesState"
          invalid-feedback="Date field is required"
          label="Date*"
          label-for="date"
        >
          <flat-pickr
            id="date"
            :state="groupDatesState"
            input-id="date"
            v-model="groupDates"
            class="form-control"
            :config="configPickr"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </tr>
</template>

<script>
import { VBToggle, VBModal } from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import homeStoreModule from "./homeStoreModule";
import vSelect from "vue-select";
import moment from "moment";
import VueContext from "vue-context";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import Cleave from "vue-cleave-component";

// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    vSelect,
    VueContext,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },

  data() {
    return {
      input1: "",
      input1state: null,
      input2: "",
      input2state: null,
      input3: "",
      input3state: null,
      hotelMenuData: [],

      newHotelName: "",
      newHotelPrice: "",
      newHotelStaff: "",

      enabledGroupDates: [],
      groupDates: null,
      modalDate: null,
    };
  },

  props: {
    saveDatas: Array,
    xIndex: Number,
    formShow: Boolean,
    x: Number,
    modalId: String,
    modalGroupDates: String,
    newHotelJob: null,
    newHotelX: Number,
    newHotelY: Number,
    indexColors: Array,
    editList: Array,
  },

  mounted() {},

  computed: {
    newHotelNameState() {
      return this.newHotelName != null && this.newHotelName != ""
        ? true
        : false;
    },
    newHotelPriceState() {
      return this.newHotelPrice != null && this.newHotelPrice != ""
        ? true
        : false;
    },
    newHotelStaffState() {
      return this.newHotelStaff != null && this.newHotelStaff != ""
        ? true
        : false;
    },

    groupDatesState() {
      return this.groupDates != null && this.groupDates != "" ? true : false;
    },

    configPickr() {
      return {
        mode: "multiple",
        locale: { firstDayOfWeek: 1 },
        dateFormat: "d/m/Y",
        enable: this.enabledGroupDates,
        allowInput: true,
        plugins: [
          ShortcutButtonsPlugin({
            button: [
            {
                label: 'Select All',
              },
              {
                label: 'Clear Selection',
              },
              {
                label: 'Until Job',
              },
              {
                label: 'After Job',
              },
              {
                label: 'Until Today',
              },
              {
                label: 'After Today',
              },
            ],
            label: "or",
            onClick: (index, fp) => {
              let targetDateParts = this.modalDate.split("-"); // Split the date string
              let targetDate = new Date(
                targetDateParts[0], // Year
                targetDateParts[1] - 1, // Month (subtract 1 since months are 0-indexed)
                targetDateParts[2] // Day
              );

              let today = new Date();

              let dates = [];
              switch (index) {
                case 0:
                  dates = this.enabledGroupDates;

                  break;
                case 1:
                  dates = [];

                  break;
                case 2:
                  dates = this.enabledGroupDates.filter((dateString) => {
                    // Convert the string date to a JavaScript Date object

                    return new Date(dateString) <= targetDate;
                  });

                  break;
                case 3:
                  dates = this.enabledGroupDates.filter((dateString) => {
                    // Compare the date to the target date

                    return new Date(dateString) > targetDate;
                  });

                  break;
                case 4:
                  dates = this.enabledGroupDates.filter((dateString) => {
                    // Compare the date to the target date

                    return new Date(dateString) < today;
                  });

                  break;
                case 5:
                  dates = this.enabledGroupDates.filter((dateString) => {
                    // Compare the date to the target date

                    return new Date(dateString) > today;
                  });

                  break;
              }
              if(dates.length>0){
                let newDates = [];
              dates.forEach(element => {
                let newDate = new Date(element)

                const day = newDate.getUTCDate();
                const month = newDate.getUTCMonth() + 1; // Months are 0-indexed, so add 1
                const year = newDate.getUTCFullYear();
                const dmYDate = `${day}/${month}/${year}`;
                newDates.push(dmYDate);
                
              });
         
              fp.setDate(newDates);

              }
              else{
                fp.setDate([]);

              }
            },
          }),
        ],
      };
    },
  },

  watch: {
    input1(val) {
      if (val) {
        this.input1state = true;
      }
    },
    input2(val) {
      if (val) {
        this.input2state = true;
      }
    },

    input3(val) {
      if (val) {
        this.input3state = true;
      }
    },
  },

  methods: {
    groupDatesHandleCancel() {
      this.$emit("update:formShow", true);
      this.modalDate = null;
      this.$emit("get-select-week");
    },

    checkFormValidityGroupDates() {
      const groupDatesValid = this.$refs.groupDatesRules.checkValidity();

      return groupDatesValid;
    },

    dateFormat(val) {
      moment.locale("en-US");
      return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
    },

    dateFormatYmdToYmd(val) {
      moment.locale("en-US");
      return moment(val, "YYYY-MM-DD").format("YYYY-MM-DD");
    },

    dateFormatYMD(val) {
      moment.locale("en-US");
      return moment(val, "DD/MM/YYYY").format("YYYY-MM-DD");
    },

    checkFormValidity() {
      const nameValid = this.$refs.hotelRules.checkValidity();

      return nameValid;
    },

    onShow(hotel, job) {
      if (this.currentHotel) {
        this.currentHotel.popoverShow = false;
      }
      this.input1 = hotel.name;
      this.input2 = hotel.price;
      this.input3 = hotel.numberOfStaff;

      this.input1state = null;
      this.input2state = null;
      this.input3state = null;
      this.currentHotel = hotel;
    },

    onClose(hotel) {
      hotel.popoverShow = false;
    },

    onOk(job, hotel) {
      if (!this.input1) {
        this.input1state = false;
      }
      if (!this.input2) {
        this.input2state = false;
      }
      if (!this.input3) {
        this.input3state = false;
      }
      if (this.input1 && this.input2 && this.input3) {
        // let newHotel = job.hotel.filter((e)=>{
        //     e.id == hotel.id
        // })

        // newHotel.title = this.input1
        // newHotel.price = this.input2
        // newHotel.numberOfStaff = this.input3

        let indexToUpdate = job.hotel.findIndex((h) => h.name === hotel.name);
        if (indexToUpdate !== -1) {
          let hotelToUpdate = job.hotel[indexToUpdate];
          job.oldHotelName = hotelToUpdate.name;
          hotelToUpdate.name = this.input1;
          hotelToUpdate.price = this.input2;
          hotelToUpdate.numberOfStaff = this.input3;

          job.hotel.splice(indexToUpdate, 1, hotelToUpdate);
        }

        job.editHotel = hotel;
        this.onClose(hotel);

        if (job.group_dates.length > 1) {
          if (this.editList.length == 0) {
            this.$bvModal
              .msgBoxConfirm(
                "Would you like to update the hotel for all days of this event?",
                {
                  title: "Please Confirm",
                  size: "sm",
                  okVariant: "primary",
                  okTitle: "Yes",
                  cancelTitle: "No",
                  cancelVariant: "outline-secondary",

                  hideHeaderClose: false,
                  noCloseOnBackdrop: true,
                  noCloseOnEsc: true,
                  centered: true,
                }
              )
              .then((value) => {
                if (value != null) {
                  if (value) {
                    this.enabledGroupDates = [];

                    this.enabledGroupDates = job.group_dates
                      .map((e) => {
                        if (this.dateFormatYmdToYmd(e.date) != job.date) {
                        return e.date;
                        }
                      })
                      .flat(1);

                    this.groupDates = job.group_dates
                      .map((e) => {
                        return this.dateFormat(e.date);
                      })
                      .flat(1)
                      .join(", ");

                    this.$bvModal.show(this.modalGroupDates);
                    this.modalDate = job.date;

                    const modalPromise = new Promise((resolve) => {
                      this.$refs[this.modalGroupDates].$once("ok", () => {
                        resolve();
                      });
                    });

                    modalPromise.then(() => {
                      job.updateDates = this.groupDates.split(",").map((e) => {
                        return this.dateFormatYMD(e);
                      });
                      store
                        .dispatch("home/editGroupEventHotel", job)
                        .then((res) => {
                          this.$emit("update:formShow", true);
                          this.$emit("get-select-week");
                        })
                        .catch((error) => {
                          this.$emit("update:formShow", false);
                          console.log(error);
                        });
                    });
                  } else {
                    store
                      .dispatch("home/saveEventHotel", job)
                      .then((res) => {
                        job = res.data;

                        this.$emit("update:formShow", true);
                        this.$emit("get-select-week");
                      })
                      .catch((error) => {
                        this.$emit("update:formShow", false);
                        console.log(error);
                      });
                  }
                }
              });
          } else {

            job.editList = this.editList;
            store
              .dispatch("home/editHotelEditList", job)
              .then((res) => {
                this.$emit("update:formShow", true);
                this.$emit("get-select-week");
              })
              .catch((error) => {
                this.$emit("update:formShow", false);
                this.$emit("get-select-week");

                console.log(error);
              });


          }
        } else {
          store
            .dispatch("home/saveEventHotel", job)
            .then((res) => {
              job = res.data;

              this.$emit("update:formShow", true);
              this.$emit("get-select-week");
            })
            .catch((error) => {
              this.$emit("update:formShow", false);
              console.log(error);
            });
        }
      }
    },

    removeHotel(job, x, y, hotel) {
      job.removeHotel = hotel;

      if (job.group_dates.length > 1) {
        if (this.editList.length == 0) {
          this.$bvModal
            .msgBoxConfirm(
              "Would you like to delete the hotel from all days of this event?",
              {
                title: "Please Confirm",
                size: "sm",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "outline-secondary",

                hideHeaderClose: false,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                centered: true,
              }
            )
            .then((value) => {
              if (value != null) {
                if (value) {
                  this.enabledGroupDates = [];

                  this.enabledGroupDates = job.group_dates
                    .map((e) => {
                      if (this.dateFormatYmdToYmd(e.date) != job.date) {
                      return e.date;
                      }
                    })
                    .flat(1);

                  this.groupDates = job.group_dates
                    .map((e) => {
                      return this.dateFormat(e.date);
                    })
                    .flat(1)
                    .join(", ");

                  this.$bvModal.show(this.modalGroupDates);
                  this.modalDate = job.date;

                  const modalPromise = new Promise((resolve) => {
                    this.$refs[this.modalGroupDates].$once("ok", () => {
                      resolve();
                    });
                  });

                  modalPromise.then(() => {
                    job.updateDates = this.groupDates.split(",").map((e) => {
                      return this.dateFormatYMD(e);
                    });

                    store
                      .dispatch("home/removeGroupEventHotel", job)
                      .then((res) => {
                        this.$emit("update:formShow", true);
                        this.$emit("get-select-week");
                      })
                      .catch((error) => {
                        console.log("error delete ", error);
                      });
                  });
                } else {
                  store
                    .dispatch("home/removeEventHotel", job)
                    .then((res) => {
                      this.$emit("update:formShow", true);
                      this.$emit("get-select-week");
                    })
                    .catch((error) => {
                      console.log("error delete ", error);
                    });
                }
              } else {
                this.$emit("get-select-week");
              }
            });
        } else {
          job.editList = this.editList;
          store
            .dispatch("home/removeHotelEditList", job)
            .then((res) => {
              this.$emit("update:formShow", true);
              this.$emit("get-select-week");
            })
            .catch((error) => {
              console.log("error delete ", error);
            });
        }
      } else {
        this.$emit("update:formShow", true);

        let newList = job.hotel.filter((e) => {
          return e.name != hotel.name;
        });
        job.hotel = [];
        job.hotel = newList;

        store
          .dispatch("home/removeEventHotel", job)
          .then((res) => {
            this.$emit("update:formShow", true);
            this.$emit("get-select-week");
          })
          .catch((error) => {
            console.log("error delete ", error);
          });
      }
    },

    handleClickHotel(event, hotel, index, job, x, y) {
      this.hotelMenuData = [
        { icon: "HomeIcon", text: "Add new Hotel", job: job, x: x, y: y },
        {
          icon: "EditIcon",
          text: "Edit Hotel",
          hotel: hotel,
          index: index,
          x: x,
          y: y,
        },
        {
          icon: "XIcon",
          text: "Remove Hotel",
          job: job,
          hotel: hotel,
          x: x,
          y: y,
        },
      ];

      if (job.length != 0) {
        this.$refs.hotelMenuData.open(event, job);
      }
    },

    optionClickedHotel(text, data) {
      if (text == "Add new Hotel") {
        this.$emit("update:newHotelJob", data.job);
        this.$emit("update:newHotelX", data.x);
        this.$emit("update:newHotelY", data.y);

        this.$nextTick(() => {
          this.$bvModal.show(this.modalId);
          this.modalDate = data.job.date;
        });
      } else if (text == "Edit Hotel") {
        const id = "popover-badge-" + data.y + "-" + data.x + "-" + data.index;
        this.$root.$emit("bv::show::popover", id);
      } else if (text == "Remove Hotel") {
        this.removeHotel(data.job, data.x, data.y, data.hotel);
      }
    },

    handleHotelOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      this.modalOkHotel();
    },

    resetModal() {
      // this.$emit('update:newHotelJob', null);

      this.newHotelName = null;
      this.newHotelPrice = null;
      this.newHotelStaff = null;
      // this.$emit('update:newHotelY', null);
      // this.$emit('update:newHotelX', null);
    },

    modalOkHotel() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide(this.modalId);
      });

      this.$nextTick(() => {
        if (this.newHotelJob.hotel == null) {
          this.newHotelJob.hotel = [];

          this.newHotelJob.hotel.push({
            name: this.newHotelName,
            price: this.newHotelPrice,
            numberOfStaff: this.newHotelStaff,
          });
        } else {
          this.newHotelJob.hotel.push({
            name: this.newHotelName,
            price: this.newHotelPrice,
            numberOfStaff: this.newHotelStaff,
          });
        }

        this.newHotelJob.newHotel = {
          name: this.newHotelName,
          price: this.newHotelPrice,
          numberOfStaff: this.newHotelStaff,
        };

        if (
          this.saveDatas[this.newHotelY - 1].jobs[this.newHotelX - 1]
            .group_dates.length > 1
        ) {
          if (this.editList.length == 0) {
            this.$bvModal
              .msgBoxConfirm(
                "Would you like to update the hotel list of the other days of this event?",
                {
                  title: "Please Confirm",
                  size: "sm",
                  okVariant: "primary",
                  okTitle: "Yes",
                  cancelTitle: "No",
                  cancelVariant: "outline-secondary",
                  style: "z-index:999;",

                  hideHeaderClose: false,
                  noCloseOnBackdrop: true,
                  noCloseOnEsc: true,
                  centered: true,
                }
              )
              .then((value) => {
                if (value != null) {
                  if (value) {
                    this.enabledGroupDates = [];

                    this.enabledGroupDates = this.newHotelJob.group_dates
                      .map((e) => {
                        if(this.dateFormatYmdToYmd(e.date) != this.saveDatas[this.newHotelY - 1].jobs[this.newHotelX - 1].date){
                        return e.date;
                        }
                      })
                      .flat(1);

                    this.groupDates = this.newHotelJob.group_dates
                      .map((e) => {
                        if(this.dateFormatYmdToYmd(e.date) != this.saveDatas[this.newHotelY - 1].jobs[this.newHotelX - 1].date){

                        return this.dateFormat(e.date);
                      }

                      })
                      .flat(1)
                      .join(", ");

                    this.$bvModal.show(this.modalGroupDates);
                    this.modalDate = this.newHotelJob.date;

                    const modalPromise = new Promise((resolve) => {
                      this.$refs[this.modalGroupDates].$once("ok", () => {
                        resolve();
                      });
                    });

                    modalPromise.then(() => {
                      this.newHotelJob.updateDates = this.groupDates
                        .split(",")
                        .map((e) => {
                          return this.dateFormatYMD(e);
                        });

                      store
                        .dispatch("home/saveGroupEventHotel", this.newHotelJob)
                        .then((res) => {
                          this.$bvModal.hide(this.modalGroupDates);

                          this.$emit("update:formShow", true);
                          this.$emit("get-select-week");
                        })
                        .catch((error) => {
                          this.$emit("update:formShow", false);
                          console.log(error);
                        });
                    });
                  } else {
                    store
                      .dispatch("home/saveEventHotel", this.newHotelJob)
                      .then((res) => {
                        this.$emit("update:newHotelJob", res.data);

                        if (this.newHotelJob.id == res.data.id) {
                          this.saveDatas[this.newHotelY - 1].jobs[
                            this.newHotelX - 1
                          ].hotel = res.data.hotel;
                          this.$emit("update:newHotelJob", null);
                          this.newHotelName = null;
                          this.newHotelPrice = null;
                          this.newHotelStaff = null;
                          this.$emit("update:newHotelY", null);
                          this.$emit("update:newHotelX", null);
                        }

                        this.$emit("update:formShow", false);
                      })
                      .catch((error) => {
                        this.$emit("update:formShow", false);
                        console.log(error);
                      });
                  }
                }
              });
          } else {
            this.newHotelJob.editList = this.editList;

            store
              .dispatch("home/saveHotelEditList", this.newHotelJob)
              .then((res) => {
                this.$emit("update:newHotelJob", res.data);

                if (this.newHotelJob.id == res.data.id) {
                  this.saveDatas[this.newHotelY - 1].jobs[
                    this.newHotelX - 1
                  ].hotel = res.data.hotel;
                  this.$emit("update:newHotelJob", null);
                  this.newHotelName = null;
                  this.newHotelPrice = null;
                  this.newHotelStaff = null;
                  this.$emit("update:newHotelY", null);
                  this.$emit("update:newHotelX", null);
                }

                
                this.$emit("update:formShow", true);
                this.$emit("get-select-week");

              })
              .catch((error) => {
                this.$emit("update:formShow", false);
                console.log(error);
              });
          }
        } else {
          store
            .dispatch("home/saveEventHotel", this.newHotelJob)
            .then((res) => {
              this.$emit("update:newHotelJob", res.data);
              if (this.newHotelJob.id == res.data.id) {
                this.saveDatas[this.newHotelY - 1].jobs[
                  this.newHotelX - 1
                ].hotel = res.data.hotel;
                this.$emit("update:newHotelJob", null);
                this.newHotelName = null;
                this.newHotelPrice = null;
                this.newHotelStaff = null;
                this.$emit("update:newHotelY", null);
                this.$emit("update:newHotelX", null);
              }
              this.$emit("update:formShow", false);
            })
            .catch((error) => {
              this.$emit("update:formShow", false);
              console.log(error);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#plannerCover {
  font-size: 0.9em;
  line-height: 1em;
}

#buttonsCard {
  // position: sticky;
  top: 130px;
}

.weekNumbers {
  margin-right: 0.1em;
  margin-bottom: 0.1em;
  font-size: 0.85em;
  padding: 0.4em;
  height: 2em;
  width: 2em;
}

.allWeeks {
  margin-right: 0.1em;
  margin-bottom: 0.1em;
  font-size: 0.85em;
  padding: 0.4em;
  height: 2em;
}

#weeksTwentyOne {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

#weeksTwentyOne::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#weeksTwentyOne::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f5f5f5;
}

#weeksTwentyOne::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #133842;
}

td {
  min-width: 130px;
  text-align: center;
  vertical-align: middle;
  height: 20px;
  border-bottom: 0px groove #00000020;
}

th {
  position: sticky;
  left: 0;
  min-width: 150px;

  background-color: rgb(0, 64, 75);
  color: white;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 0px groove #00000020;
  padding-left: 5px;
  outline: 1px groove #00000020;
}

// :nth-child(-n + 5)
thead {
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
  background-color: white;
  // border: 1px solid black;
}

tbody td {
  z-index: 9;
}

.leftTitle {
  z-index: 9;
  vertical-align: middle;
  border-bottom: 0px groove #00000020;
  padding-left: 5px;
  outline: 1px solid #00000020;
}

.plannerTd {
  z-index: 9;
  outline: 1px solid #00000020;
  padding-bottom: 5px;
  padding-top: 5px;
  vertical-align: middle;
  max-width: 150px;
}

.plannerTdStaff {
  z-index: 9;
  outline: 1px solid #00000020;
  padding-bottom: 0px;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: top;
}

li {
  list-style-type: none;
}

.plannerTdStaff .wwsuccess {
  padding: 0.25rem 0.5rem;
  text-align: center;
  box-shadow: 0 0 5px #82868b;
  white-space: nowrap;
  font-size: 0.8em;
}

.errorStaff {
  outline: 1px solid rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 8px 1px red !important;
  background-color: rgba(255, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid black;
}

.warningStaff {
  outline: 1px solid rgba(255, 0, 0, 0.1);
  box-shadow: 0 0 8px 1px blue !important;
  background-color: rgba(255, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid black;
}

.select-size-sm .vs__search {
  height: 2px;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(120px);
}

.noteTextArea {
  background-color: #fdfd96;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  top: 0;
  width: 90%;
  word-wrap: break-word;
  // margin-left: 5px;
  // margin-right: 5px;
}

.noteTextArea::-webkit-scrollbar {
  width: 8px;
}

.noteTextArea::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #133842;
}

.wsuccess {
  background-color: #5cb85c;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.wwarning {
  background-color: #f0ad4e;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.wwsecondary {
  background-color: #6e6e6e;
  text-align: center;
  // padding-top: 0.25rem;
  // padding-bottom: 0.25rem;
  // padding-right: 0.15rem;
  // padding-left: 0.15rem;
  // box-shadow: 0 0 5px #82868b;
  font-size: 0.8rem;
  color: white;
}

.fakeBadge {
  display: inline-block;
  padding: 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin-right: 0.4em;
  margin-left: 0.4em;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #0d272e;
  color: white;
}

.fakeBadgeNotBooked {
  display: inline-block;
  padding: 0.4em;
  font-size: 75%;
  line-height: 1;
  text-align: center;
  vertical-align: baseline;
  border-radius: 0.25rem;
  margin-right: 0.4em;
  margin-left: 0.4em;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #1e7d97;
  color: white;
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~vue-context/dist/css/vue-context.css";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.vue-treeselect__value-remove {
  display: none;
}

.b-overlay {
  inset: -30px !important;
}
</style>

<style>
.shortcut-buttons-flatpickr-button{
    width:28%;
  border-radius: 5px;
  background: white;
  border: 1px solid #d8d6de;
  font-size: small;
}
</style>
